import { createApp } from "vue";
import App from "./App.vue";
import "./registerServiceWorker";
import router from "./router";
import "./styles/app.css"; // Here
import { createHead } from "@vueuse/head";
import { createPinia } from "pinia";

const head = createHead();
createApp(App).use(createPinia()).use(router).use(head).mount("#app");

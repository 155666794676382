
import { defineComponent } from "vue";
import WeSupportedSlider from "@/components/common/we_supported_slider/WeSupportedSlider.vue";
import "vue3-carousel/dist/carousel.css";

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import { Carousel, Slide } from "vue3-carousel";
class Block {
  title: string;
  subtitle: string;
  constructor(title: string, subtitle: string) {
    this.title = title;
    this.subtitle = subtitle;
  }
}

export default defineComponent({
  name: "DetailComponent",
  props: {
    title: String,
    subtitle: String,
    blockLeft: Block,
    blockMiddle: Block,
    blockRight: Block,
  },
  components: {
    WeSupportedSlider,
    Carousel,
    Slide,
  },
  data(): {
    // observer: any;
    renderComponent: boolean;
    selected: number;
    isMounted: boolean;
  } {
    return {
      renderComponent: true,
      selected: 0,
      // observer: null,
      isMounted: false,
    };
  },

  watch: {
    $route(to, from) {
      console.log("SCROLL");
      window.scrollTo(0, 0);
    },
  },
  mounted() {
    this.isMounted = true;
  },

  methods: {
    slideTo(index: number) {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      (this.$refs.myCarousel as any).slideTo(index);

      this.selected = index;
    },

    setBackgroundColor() {
      switch (this.$route.path) {
        case "/eko-a-enviro": {
          return "bg-bln-eko";
        }
        case "/dusevne-zdravie": {
          return "bg-bln-health";
        }
        case "/vzdelavanie": {
          return "bg-bln-yellow";
        }
      }
    },
    setSelectedColor() {
      switch (this.$route.path) {
        case "/eko-a-enviro": {
          return "bg-bln-eko";
        }
        case "/dusevne-zdravie": {
          return "bg-bln-health";
        }
        case "/vzdelavanie": {
          return "bg-bln-yellow";
        }
      }
    },
    setUnselectedColor() {
      switch (this.$route.path) {
        case "/eko-a-enviro": {
          return "bg-[#72D08483]";
        }
        case "/dusevne-zdravie": {
          return "bg-[#6191FE83]";
        }
        case "/vzdelavanie": {
          return "bg-[#fdc027b2]";
        }
      }
    },
    setColor() {
      switch (this.$route.path) {
        case "/eko-a-enviro": {
          return "text-bln-eko";
        }
        case "/dusevne-zdravie": {
          return "text-bln-health";
        }
        case "/vzdelavanie": {
          return "text-bln-yellow-text";
        }
      }
    },
  },
});

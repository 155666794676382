
import Header from "@/components/common/Header.vue";
import Footer from "@/components/common/Footer.vue";
import { useHead } from "@vueuse/head";
import { defineComponent } from "vue";
import getSiteMeta from "./utils/getSiteMeta";

export default defineComponent({
  name: "HomeView",

  components: {
    Header,
    Footer,
  },

  setup() {
    const meta = {
      title: "Podporujeme dobré nápady | Nadácia Blue Lemons",
      description:
        "Naša nadácia podporuje dobré nápady v oblastiach vzdelávanie, eko a enviro a duševné zdravie.",
      url: "http://nadaciabluelemons.sk/",
    };

    useHead({
      title: meta.title,
      meta: getSiteMeta(meta),
    });
  },
});

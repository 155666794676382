
import { defineComponent } from "vue";
import Newsletter from "@/components/common/Newsletter.vue";
import { useMainStore } from "@/store/useMainStore";

export default defineComponent({
  name: "FooterComponent",
  components: {
    Newsletter,
  },
  data: () => {
    return {
      learn: true,
      eco: false,
      health: false,
      clicked: 0,
      clickedSave: 0,
      enteredMouse: false,
      close: true,
    };
  },
  methods: {
    setParametersByPath() {
      const store = useMainStore();

      if (this.$route.path == "/vzdelavanie" || store.key === "062a0213") {
        this.eco = false;
        this.learn = true;
        this.health = false;
      } else if (
        this.$route.path == "/eko-a-enviro" ||
        store.key === "02670138"
      ) {
        this.eco = true;
        this.learn = false;
        this.health = false;
      } else if (
        this.$route.path == "/dusevne-zdravie" ||
        store.key === "08870277"
      ) {
        this.eco = false;
        this.learn = false;
        this.health = true;
      } else {
        this.eraseSelectedSetBlue();
      }
    },

    getBackGroundColor() {
      this.setParametersByPath();
      if (this.learn) {
        return "bg-[" + "#fdc027" + "]";
      } else if (this.eco) {
        return "bg-[" + "#72d084" + "]";
      } else if (this.health) {
        return "bg-[" + "#6191fe" + "]";
      } else {
        return "bg-[" + "#29adf2" + "]";
      }
    },
    getFontColor() {
      if (this.learn || this.eco) {
        return "text-bln-black";
      } else {
        return "text-bln-white";
      }
    },
    eraseSelectedSetBlue() {
      this.learn = false;
      this.eco = false;
      this.health = false;
    },
    selectedCheck() {
      return this.learn || this.eco || this.health;
    },
    onClicked() {
      this.close = true;
    },
  },
});


import { defineComponent } from "vue";
import Data from "../../api/projectDetails.json";
import WeSupportedSlider from "@/components/common/we_supported_slider/WeSupportedSlider.vue";
import { useMainStore } from "@/store/useMainStore";

export default defineComponent({
  components: {
    WeSupportedSlider,
  },
  mounted() {
    this.getProject();
    window.scrollTo(0, 0);
  },
  name: "ProjectDetail",
  // props: ["data"],
  data: () => {
    return {
      learn: false,
      eco: false,
      health: false,
      color: "",
      data: {},
    };
  },
  watch: {
    $route() {
      this.getProject();
      window.scrollTo(0, 0);
    },
  },
  methods: {
    getProject() {
      Data.projects.forEach((element) => {
        if ("/podporili-sme/" + element.path == this.$route.path) {
          this.data = element;
        }
      });
    },
    getImgUrl: function (img: string) {
      return require("@/assets/" + img);
    },
    setColor(key: string) {
      const store = useMainStore();
      switch (key) {
        case "Eko a enviro": {
          store.updateKey("02670138");
          this.eco = true;
          this.health = false;
          this.learn = false;
          return "text-bln-eko";
        }
        case "Zdravie": {
          store.updateKey("08870277");
          this.eco = false;
          this.health = true;
          this.learn = false;
          return "text-bln-health";
        }
        case "Vzdelávanie": {
          store.updateKey("062a0213");
          this.eco = false;
          this.health = false;
          this.learn = true;
          return "text-bln-yellow-text";
        }
      }
    },
  },
});

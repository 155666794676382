
import { useHead } from "@vueuse/head";
import { defineComponent } from "vue";
import getSiteMeta from "../utils/getSiteMeta";
import { useRoute } from "vue-router";
import DetailComponent from "../components/common/Detail.vue";

class Block {
  title: string;
  subtitle: string;
  constructor(title: string, subtitle: string) {
    this.title = title;
    this.subtitle = subtitle;
  }
}

export default defineComponent({
  name: "DetailView",
  components: {
    DetailComponent,
  },
  data: () => {
    return {
      leftBlock: Block,
      middleBlock: Block,
      rightBlock: Block,
    };
  },

  setup() {
    const route = useRoute();
    const meta = {
      title:
        route.name === "vzdelavanie"
          ? "Vzdelávanie | Nadácia Blue Lemons"
          : route.name === "eko-a-enviro"
          ? "Eko a enviro | Nadácia Blue Lemons"
          : "Zdravie | Nadácia Blue Lemons",
      description:
        route.name === "vzdelavanie"
          ? "Adaptáciu širokej spoločnosti do prostredia, v ktorom práca, komunikácia a prístup k informáciám prebieha prostredníctvom digitálnych technológií považujeme za kľúčové pre budúcnosť."
          : route.name === "eko-a-enviro"
          ? "Ekológia a životné prostredie potrebuje podporu udržateľnosti. Veríme, že to je možné dosiahnuť postupnými krokmi a malými nápadmi, ktoré musia začať od nás a našich komunít."
          : "Zdravie považujeme za kľúčové v téme čo najlepšieho využívania ľudského potenciálu. Vďaka zdraviu vie byť človek aktívnou a plnohodnotnou súčasťou svojho pracoviska, rodiny, komunity a priateľov.",
      url:
        route.name === "vzdelavanie"
          ? "http://nadaciabluelemons.sk/vzdelavanie"
          : route.name === "eko-a-enviro"
          ? "http://nadaciabluelemons.sk/eko-enviro"
          : "http://nadaciabluelemons.sk/dusevne-zdravie",
    };

    useHead({
      title: meta.title,
      meta: getSiteMeta(meta),
    });
  },

  mounted() {
    window.scrollTo(0, 0);
  },

  methods: {
    setComponentTitle() {
      switch (this.$route.path) {
        case "/eko-a-enviro": {
          return "Eko a enviro";
        }
        case "/dusevne-zdravie": {
          return "Zdravie";
        }
        case "/vzdelavanie": {
          return "Vzdelávanie";
        }
      }
    },
    setComponentSubTitle() {
      switch (this.$route.path) {
        case "/eko-a-enviro": {
          return "Ekológia a životné prostredie potrebuje podporu udržateľnosti. Veríme, že to je možné dosiahnuť postupnými krokmi a malými nápadmi, ktoré musia začať od nás a našich komunít.";
        }
        case "/dusevne-zdravie": {
          return "Zdravie považujeme za kľúčové v téme čo najlepšieho využívania ľudského potenciálu. Vďaka zdraviu vie byť človek aktívnou a plnohodnotnou súčasťou svojho pracoviska, rodiny, komunity a priateľov.";
        }
        case "/vzdelavanie": {
          return "Adaptáciu širokej spoločnosti do prostredia, v ktorom práca, komunikácia a prístup k informáciám prebieha prostredníctvom digitálnych technológií považujeme za kľúčové pre budúcnosť.";
        }
      }
    },
    setComponentLeftBlock() {
      switch (this.$route.path) {
        case "/eko-a-enviro": {
          return new Block(
            "Zelené projekty",
            "Podpora iniciatív a zelených projektov, ktoré sa cieľavedome venujú ochrane biodiverzity a prispievajú k zmierňovaniu ekologickej krízy."
          );
        }
        case "/dusevne-zdravie": {
          return new Block(
            "Podpora zdravia",
            "Podpora iniciatív zameraných na zlepšovanie fyzického ako aj duševného zdravia."
          );
        }
        case "/vzdelavanie": {
          return new Block(
            "Digitálna gramotnosť",
            "Podporujeme aktivity/projekty v oblasti vzdelávania, zamerané na zlepšovanie digitálnej gramotnosti na školách (základné, stredné, vysoké školy/univerzity, neziskovky)."
          );
        }
      }
    },
    setComponentMiddleBlock() {
      switch (this.$route.path) {
        case "/eko-a-enviro": {
          return new Block(
            "Klíma",
            "Podpora projektov, ktorých cieľom je zmierniť klimatickú zmenu a prispieť k dosiahnutiu uhlíkovo neutrálneho Slovenska."
          );
        }
        case "/dusevne-zdravie": {
          return new Block(
            "Podpora partnerstiev",
            "Podpora partnerstiev s organizáciami, ktorých ciele a výstupy prispievajú k ochrane a zlepšovaniu fyzického a duševného zdravia."
          );
        }
        case "/vzdelavanie": {
          return new Block(
            "Celoživotné vzdelávanie",
            "Podporovať aktivity/projekty, zamerané na získanie praktických skúseností z oblasti projektového riadenia, tvorby digitálnych inovácií, ako aj zlepšovanie prezentačných zručností."
          );
        }
      }
    },
    setComponentRightBlock() {
      switch (this.$route.path) {
        case "/eko-a-enviro": {
          return new Block(
            "Obnoviteľné zdroje",
            "Podpora digitálnych inovácií podporujúcich využívanie obnoviteľných zdrojov energie."
          );
        }
        case "/dusevne-zdravie": {
          return new Block(
            "Podpora nápadov",
            "Podpora nápadov, ktoré prispievajú k pozitívnemu dianiu v komunite, utužujú dobré susedské vzťahy a skrášľujú verejné priestranstvá."
          );
        }
        case "/vzdelavanie": {
          return new Block(
            "Kritické myslenie",
            "V spolupráci s odborníkmi vo vzdelávaní chceme pozitívne ovplyvňovať život generácií dospievajúcich v modernom technologickom svete s dôrazom na rozvoj ich kritického myslenia, charakterových vlastností a vnímavosti voči sebe i druhým."
          );
        }
      }
    },
  },
});


import PhotoSlider from "@/components/shared/slider/PhotoSlider.vue";
import MobileSliderInfo from "@/components/shared/slider/MobileSliderInfo.vue";
import { useHead } from "@vueuse/head";
import { defineComponent } from "vue";
import getSiteMeta from "../utils/getSiteMeta";

export default defineComponent({
  name: "AboutView",

  components: {
    PhotoSlider,
    MobileSliderInfo,
  },

  mounted() {
    window.scrollTo(0, 0);
  },

  data() {
    return {
      data: [
        {
          title: "Koho podporujeme",
          text: "Podporujeme projekty realizované fyzickými osobami - nepodnikateľmi. A to najmä skupiny študentov VŠ, SŠ, mimovládne organizácie, neziskové organizácie, nezávislé iniciatívy, komunity,spoločenstvá a združenia.",
        },
        {
          title: "Ako podporujeme",
          text: "Realizované projekty žiadateľov podporujeme pokrytím finančných nákladov priamo spojených s realizáciou projektu, nákupom hmotných a nehmotných potrieb, organizačným zabezpečením alebo inak, podľa špecifík nápadu.",
        },
      ],
    };
  },

  setup() {
    const meta = {
      title: "O nadácii | Nadácia Blue Lemons",
      description:
        "Nadácia Blue Lemons je podporný subjekt, ktorého cieľom je sprostredkovať pomoc a podporu fyzickým osobám a nezávislým organizáciám a komunitám za účelom zlepšovania spoločenského života.",
      url: "http://nadaciabluelemons.sk/o-nas",
    };

    useHead({
      title: meta.title,
      meta: getSiteMeta(meta),
    });
  },
});

<template>
  <div :key="item" v-for="(item, index) in accordion">
    <accordion-item
      :index="index"
      :heading="item.heading"
      :content="item.content"
    />
  </div>
</template>

<script>
import AccordionItem from "./AccordionItem.vue";

export default {
  components: {
    AccordionItem,
  },
  props: {
    accordion: {
      type: Object,
    },
  },
};
</script>

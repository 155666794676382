import { createRouter, createWebHistory, RouteRecordRaw } from "vue-router";
import HomeView from "../views/HomeView.vue";
import DetailView from "../views/DetailView.vue";
import AboutView from "../views/AboutView.vue";
import SupportedView from "@/views/SupportedView.vue";
import ProjectDetailView from "@/views/ProjectDetailView.vue";
import ContactView from "@/views/ContactView.vue";
import PeopleView from "@/views/PeopleView.vue";
import MediaView from "@/views/MediaView.vue";
import SupportView from "@/views/SupportView.vue";
import ChallengeView from "@/views/ChallengeView.vue";
import DocumentsView from "@/views/DocumentsView.vue";
import FAQView from "@/views/FAQView.vue";
import GDPRView from "@/views/GDPRView.vue";

const routes: Array<RouteRecordRaw> = [
  {
    path: "/",
    name: "home",
    component: HomeView,
  },
  {
    path: "/vzdelavanie",
    name: "vzdelavanie",
    component: DetailView,
  },
  {
    path: "/eko-a-enviro",
    name: "eko-a-enviro",
    component: DetailView,
  },
  {
    path: "/dusevne-zdravie",
    name: "dusevne-zdravie",
    component: DetailView,
  },
  {
    path: "/o-nadacii",
    name: "o-nadacii",
    component: HomeView,
  },
  {
    path: "/ludia",
    name: "ludia",
    component: PeopleView,
  },
  {
    path: "/podporili-sme",
    name: "podporili-sme",
    component: SupportedView,
  },
  {
    path: "/podporili-sme/:name",
    name: "o-projekte",
    component: ProjectDetailView,
  },
  {
    path: "/podpora",
    name: "podpora",
    component: SupportView,
  },
  {
    path: "/dokumenty",
    name: "dokumenty",
    component: DocumentsView,
  },
  {
    path: "/kontakt",
    name: "kontakt",
    component: ContactView,
  },
  {
    path: "/o-nas",
    name: "o-nas",
    component: AboutView,
  },
  {
    path: "/media",
    name: "media",
    component: MediaView,
  },
  {
    path: "/vyzvy",
    name: "vyzvy",
    component: ChallengeView,
  },
  {
    path: "/faq",
    name: "faq",
    component: FAQView,
  },
  {
    path: "/gdpr",
    name: "gdpr",
    component: GDPRView,
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

export default router;


import { defineComponent } from "vue";
import { useMainStore } from "@/store/useMainStore";

export default defineComponent({
  name: "NewsletterComponent",
  props: {
    eco: Boolean,
    health: Boolean,
    learn: Boolean,
    onClicked: Function,
  },
  data: () => {
    return {
      loading: false,
      subscribeActive: false,
      email: "",
      emailBlured: false,
      valid: false,
    };
  },
  methods: {
    validate: function () {
      this.emailBlured = true;
      if (this.validEmail(this.email)) {
        this.valid = true;
      }
    },
    validEmail: function (email: string) {
      var re = /(.+)@(.+){2,}\.(.+){2,}/;
      return re.test(email.toLowerCase());
    },
    getBackgroundColor() {
      const store = useMainStore();

      if (this.learn || store.key == "062a0213") {
        return "bg-[" + "#fdc027" + "]";
      } else if (this.eco || store.key == "02670138") {
        return "bg-[" + "#72d084" + "]";
      } else if (this.health || store.key == "08870277") {
        return "bg-[" + "#6191fe" + "]";
      } else {
        return "bg-[" + "#29adf2" + "]";
      }
    },
    getFontColor() {
      if (this.learn || this.eco || this.health) {
        return "text-bln-black";
      } else {
        return "text-bln-white";
      }
    },
    selectedCheck() {
      return this.learn || this.eco || this.health;
    },

    buttonClicked() {
      this.loading = true;
      setTimeout(() => (this.loading = false), 500);
      this.subscribeActive = true;
    },
    updateCloseStatus: function () {
      this.$emit("close", true);
    },
  },
});

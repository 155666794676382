
import { defineComponent } from "vue";
import { useHead } from "@vueuse/head";
import getSiteMeta from "../utils/getSiteMeta";

export default defineComponent({
  mounted() {
    window.scrollTo(0, 0);
  },

  setup() {
    const meta = {
      title: "GDPR | Nadácia Blue Lemons",
      description: "Podmienky pre spracovanie osobných údajov.",
      url: "http://nadaciabluelemons.sk/gdpr",
    };

    useHead({
      title: meta.title,
      meta: getSiteMeta(meta),
    });
  },
});

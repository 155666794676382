
import AccordionMenu from "../components/common/accordion/AccordionMenu.vue";
import { useHead } from "@vueuse/head";
import getSiteMeta from "../utils/getSiteMeta";

export default {
  components: {
    AccordionMenu,
  },

  mounted() {
    window.scrollTo(0, 0);
  },

  setup() {
    const meta = {
      title: "FAQ | Nadácia Blue Lemons",
      description: "Často kladené otázky",
      url: "http://nadaciabluelemons.sk/faq",
    };

    useHead({
      title: meta.title,
      meta: getSiteMeta(meta),
    });
  },

  data: function () {
    return {
      accordion: [
        {
          heading: "Ako požiadať o grant?",
          content: "Všetky informácie budú čoskoro uverejnené. ",
        },
        {
          heading: "Aké druhy podpory môžeme získať?",
          content:
            "Realizované projekty žiadateľov podporujeme pokrytím finančných nákladov priamo spojených s realizáciou projektu, nákupom hmotných a nehmotných potrieb, organizačným zabezpečením alebo inak, podľa špecifík nápadu.",
        },
        {
          heading: "Na koho sa obrátiť, ak potrebujem poradiť?",
          content:
            "Napíšte nám e-mail na nadacia@bluelemons.sk a my ti veľmi radi odpovieme. ",
        },
        {
          heading: "Kto rozhoduje o schválení projektu?",
          content:
            "O schválení projektu bude rozhodovovať správna rada. Kto je jej súčasťou nájdeš na stránke ,,ľudia,,.. ",
        },
        {
          heading: "Ako sa dozviem, či nám bol projekt schválený?",
          content:
            "O schválení/neschválení projektu budeme informovať všetkých, ktorí sa zapoja do vyhlásenej výzvy. Detailné informácie doplníme, ak bude výzva vyhlásená.",
        },
        {
          heading: "Kto stojí za nadáciou?",
          content:
            "Zakladateľom nadácie je spoločnosť Blue Lemons, ktorá sa usiluje prostredníctvom digitálnych inovácii zlepšovať fyzický svet.",
        },
        {
          heading: "Môžem o grant požiadať ako fyzická osoba?",
          content: "Všetky informácie budú čoskoro uverejnené.",
        },
        {
          heading: "Kto môže požiadať o grant?",
          content:
            "Podporujeme projekty realizované fyzickými osobami - nepodnikateľmi. A to najmä skupiny študentov VŠ, SŠ, mimovládne organizácie, neziskové organizácie, nezávislé iniciatívy, komunity, spoločenstvá a združenia.",
        },
        {
          heading:
            "Môj projekt nespadá do žiadnej kategórie, môžem sa uchádzať o grant?",
          content:
            "Váš projekt by mal súvisieť s témami, ktorými sa v nadácii venujeme a mal by spĺňať všetky kritéria, ktoré budú uvedené v znení výzvy. Takýmto spôsobom zvyšujete svoje šance, aby sa váš projekt zaradil medzi podporené projekty.",
        },
        {
          heading: "Aké projekty nadácia podporuje?",
          content:
            "Naša nadácia podporuje projekty, ktoré spadajú do troch oblastí. Vzdelávanie, eko a enviro a zdravie. Viac o týchto témach sa dočítaš na našich stránkach, prípadne ti môže pomôcť, ak si pozriete typy projektov, ktoré sme už podporili.",
        },
      ],
    };
  },
};

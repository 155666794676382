import { toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = ["id"]
const _hoisted_2 = ["id"]
const _hoisted_3 = {
  key: 0,
  class: "xs:mt-[2%] self-start inline-block align-middle font-['Graphik'] font-[700] tetx-[20px] text-[#29ADF2] leading-[140%]"
}
const _hoisted_4 = {
  key: 1,
  class: "xs:mt-[2%] self-start inline-block align-middle font-['Graphik'] font-[700] tetx-[20px] leading-[140%]"
}
const _hoisted_5 = ["id"]
const _hoisted_6 = { class: "font-['Montserrat'] font-[400] xs:text-[16px] xl:tetx-[18px] xs:leading-[23px] md:leading-[25px] xl:leading-[27px]" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", { id: $props.index }, [
    _createElementVNode("div", {
      class: _normalizeClass([[_ctx.isActive ? '' : 'border-b-[1px]'], "w-[90%] cursor-pointer mt-[53px] xs:ml-[14px] md:ml-[35px] flex flex-row justify-between"]),
      onClick: _cache[0] || (_cache[0] = ($event: any) => ($options.toggle()))
    }, [
      _createElementVNode("div", {
        id: `${$props.index}-h`,
        class: _normalizeClass([[
          _ctx.isActive
            ? 'text-[#29ADF2] transition-colors duration-[0.5s]'
            : 'text-black transition-colors duration-[0.5s]',
        ], "inline-block xs:max-w-[350px] md:max-w-[650px] xxl:max-w-[100%] font-['Graphik'] font-[700] xs:text-[20px] md:text-[24px] xxl:text-[32px] xs:leading-[36px] md:leading-[41px] xl:leading-[48px]"])
      }, _toDisplayString($props.heading), 11, _hoisted_2),
      (_ctx.isActive)
        ? (_openBlock(), _createElementBlock("div", _hoisted_3, " - "))
        : _createCommentVNode("", true),
      (!_ctx.isActive)
        ? (_openBlock(), _createElementBlock("div", _hoisted_4, " + "))
        : _createCommentVNode("", true)
    ], 2),
    _createElementVNode("div", {
      id: `${$props.index}-c`,
      class: _normalizeClass([
        _ctx.isActive
          ? 'transition-all duration-[0.5s] opacity-100 max-h-[1000px] block pt-[50px] pb-[50px] xs:pl-[30px] md:pl-[47px] xl:pl-[57px] xs:pr-[30px] md:pr-[47px] xl:pr-[57px] border-[1px] rounded-[30px] border-[#29ADF2] '
          : 'transition-all duration-[0.5s] opacity-0 xs:pl-[30px] md:pl-[47px] xl:pl-[57px] xs:pr-[30px] md:pr-[47px] xl:pr-[57px] pt-[0px] pb-[0px] max-h-[0] invisible',
      ])
    }, [
      _createElementVNode("div", _hoisted_6, _toDisplayString($props.content), 1)
    ], 10, _hoisted_5)
  ], 8, _hoisted_1))
}

export default {
  data: function () {
    return {
      isActive: false,
    };
  },
  props: {
    index: {
      type: Number,
    },
    heading: {
      type: String,
    },
    content: {
      type: String,
    },
  },
  watch: {
    isActive: function (newVal: boolean, oldVal: boolean) {
      //
    },
  },
  methods: {
    toggle() {
      (this as any).isActive = !(this as any).isActive;
    },
  },
};


import { useHead } from "@vueuse/head";
import getSiteMeta from "../utils/getSiteMeta";

export default {
  mounted() {
    window.scrollTo(0, 0);
  },

  setup() {
    const meta = {
      title: "Ľudia| Nadácia Blue Lemons",
      description:
        "Za nadáciou stoja odborníci vo svojom obore, ktorí majú hlboké presvedčenie o tom, že spoločnými silami vieme meniť veci k lepšiemu.",
      url: "http://nadaciabluelemons.sk/ludia",
    };

    useHead({
      title: meta.title,
      meta: getSiteMeta(meta),
    });
  },

  data: function () {
    return {
      people: [
        {
          id: 0,
          url: "rs.png",
          handle: "rs_picture",
          heading: "Ing. Radovan Šalitroš MBA",
          subHeading: "Správca nadácie Blue Lemons",
          text: "V Blue Lemons veríme, že by naše aktivity mali prinášať úžitok nie len nám, ale aj okoliu, ktorého sme súčasťou. Vytvorili sme preto nástroj na jasnú a cielenú pomoc.",
        },
        {
          id: 0,
          url: "ms.png",
          handle: "ms_picture",
          heading: "Ing. Matúš Smoleň",
          subHeading: "Člen správnej rady",
          text: "Podpora nápadov, ktorých zámerom je pozitívny dopad na naše prostredie je to najmenej, čo môžem v rámci svojej práce urobiť. Považujem to za samozrejmosť.",
        },
        {
          id: 0,
          url: "ip.png",
          handle: "ip_picture",
          heading: "Ing. Ivo Petrenčík",
          subHeading: "Člen správnej rady",
          text: "Vážim si ľudí, ktorí majú zmysel pre tvorbu projektov s prospechom pre ostatných. V nadácii Blue Lemons môžem presne takých ľudí spoznávať a ich nápady podporovať.",
        },
        {
          id: 0,
          url: "fb.png",
          handle: "fb_picture",
          heading: "doc. Ing. František Babič, PhD.",
          subHeading: "Člen správnej rady",
          text: "Chcem žiť vo svete, v ktorom je úplne normálne, že si ľudia pomáhajú a navzájom sa podporujú. Som rád, že v Nadácii Blue Lemons môžeme túto myšlienku skutočne rozvíjať.",
        },
      ],
    };
  },
};

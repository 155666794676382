
import AccordionMenu from "../components/common/accordion/AccordionMenu.vue";
import { useHead } from "@vueuse/head";
import getSiteMeta from "../utils/getSiteMeta";

export default {
  components: {
    AccordionMenu,
  },

  setup() {
    const meta = {
      title: "Podpora | Nadácia Blue Lemons",
      description:
        "V prípade, že by ste chceli podporiť našu činnosť a stať sa tak aktívnou súčasťou napĺňania našich zámerov, podporiť nás môžete viacerými spôsobmi.",
      url: "http://nadaciabluelemons.sk/podpora",
    };

    useHead({
      title: meta.title,
      meta: getSiteMeta(meta),
    });
  },

  mounted() {
    window.scrollTo(0, 0);
  },

  data: function () {
    return {
      accordion: [
        {
          heading: "Zamestnanci",
          content: "Vaše 2% budeme môcť prijímať v roku 2023. Vopred ďakujeme",
        },
        {
          heading: "SZČO",
          content: "Vaše 2% budeme môcť prijímať v roku 2023. Vopred ďakujeme",
        },
        {
          heading: "Právnické osoby",
          content: "Vaše 2% budeme môcť prijímať v roku 2023. Vopred ďakujeme",
        },
      ],
    };
  },
};

import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_DetailComponent = _resolveComponent("DetailComponent")!

  return (_openBlock(), _createBlock(_component_DetailComponent, {
    key: _ctx.setComponentTitle(),
    title: _ctx.setComponentTitle(),
    subtitle: _ctx.setComponentSubTitle(),
    blockLeft: _ctx.setComponentLeftBlock(),
    blockMiddle: _ctx.setComponentMiddleBlock(),
    blockRight: _ctx.setComponentRightBlock()
  }, null, 8, ["title", "subtitle", "blockLeft", "blockMiddle", "blockRight"]))
}

import AccordionMenu from "../components/common/accordion/AccordionMenu.vue";
import { useHead } from "@vueuse/head";
import getSiteMeta from "../utils/getSiteMeta";

export default {
  components: {
    AccordionMenu,
  },

  mounted() {
    window.scrollTo(0, 0);
  },

  setup() {
    const meta = {
      title: "Dokumenty | Nadácia Blue Lemons",
      description:
        "Výročné správy, etický kódex a grantové pravidlá na stiahnutie.",
      url: "http://nadaciabluelemons.sk/dokumenty",
    };

    useHead({
      title: meta.title,
      meta: getSiteMeta(meta),
    });
  },

  data: function () {
    return {
      accordion: [
        {
          heading: "Výročné správy",
          content: "Túto sekciu doplníme čoskoro.",
        },
        {
          heading: "Etický kódex",
          content: "Túto sekciu doplníme čoskoro.",
        },
        {
          heading: "Grantové pravidlá",
          content: "Túto sekciu doplníme čoskoro.",
        },
      ],
    };
  },
};


import { Options, Vue } from "vue-class-component";
import WeSupportedSlider from "@/components/common/we_supported_slider/WeSupportedSlider.vue";
// import HelloWorld from "@/components/HelloWorld.vue"; // @ is an alias to /src
import { useHead } from "@vueuse/head";
import { defineComponent } from "vue";
import getSiteMeta from "../utils/getSiteMeta";

export default defineComponent({
  name: "HomeView",

  components: {
    WeSupportedSlider,
  },

  data() {
    return {
      learn: false,
      eco: false,
      health: false,
    };
  },

  mounted() {
    window.scrollTo(0, 0);
  },

  setup() {
    const meta = {
      title: "Podporujeme dobré nápady | Nadácia Blue Lemons",
      description:
        "Naša nadácia podporuje dobré nápady v oblastiach vzdelávanie, eko a enviro a duševné zdravie.",
      url: "http://nadaciabluelemons.sk/",
    };

    useHead({
      title: meta.title,
      meta: getSiteMeta(meta),
    });
  },

  learnSelected() {
    this.learn = !this.learn;
    this.eco = false;
    this.health = false;
    console.log(`learn ${this.learn}`);
  },

  ecoSelected() {
    this.eco = !this.eco;
    this.learn = false;
    this.health = false;
    console.log(`eco ${this.eco}`);
  },

  healthSelected() {
    this.health = !this.health;
    this.learn = false;
    this.eco = false;
    console.log(`health ${this.health}`);
  },
});

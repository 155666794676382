import { defineStore } from "pinia";

export const useMainStore = defineStore({
  id: "mainStore",
  state: () => ({
    key: "",
  }),

  actions: {
    updateKey(key: string) {
      if (!key) return;
      this.key = key;
    },
  },
});

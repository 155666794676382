
// import SlideItem from "./SlideItem.vue";
import "vue3-carousel/dist/carousel.css";

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import { Carousel, Slide, Pagination } from "vue3-carousel";
export default {
  components: {
    // SlideItem,
    Carousel,
    Slide,
    Pagination,
  },
  props: {
    data: [],
  },
};

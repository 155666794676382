
import { defineComponent } from "vue";
import { useMainStore } from "@/store/useMainStore";

export default defineComponent({
  name: "HeadeComponent",
  components: {},
  data: () => {
    return {
      hoverStateAbout: 0,
      show: false,
      showDoing: false,
      showContact: false,
      showAbout: false,
      showWeSupported: false,
      showChallenges: false,
      learn: false,
      eco: false,
      health: false,
      selectedWeSupported: false,
      originalColor: "#29adf2",
      color: "#29adf2",
      whoWeAre: false,
      whatAreWeDoing: false,
      isMouseOverMenu: false,
      clicked: false,
    };
  },
  methods: {
    triggerAbout() {
      setTimeout(() => {
        if (!this.isMouseOverMenu) {
          this.showAbout = false;
        }
      }, 1);
    },
    triggerDoing() {
      setTimeout(() => {
        if (!this.isMouseOverMenu) {
          this.showDoing = false;
        }
      }, 1);
    },
    setParametersByPath() {
      if (this.$route.path == "/vzdelavanie") {
        this.learn = true;
      } else if (this.$route.path == "/eko-a-enviro") {
        this.eco = true;
      } else if (this.$route.path == "/dusevne-zdravie") {
        this.health = true;
      } else {
        this.originalColor = "#29adf2";
        this.color = "#29adf2";
      }
    },

    hovercheck() {
      return (
        this.showDoing ||
        this.showContact ||
        this.showAbout ||
        this.showWeSupported ||
        this.showChallenges
      );
    },
    getColor() {
      //this.setParametersByPath();

      if (this.learn) {
        this.originalColor = "#fdc027";
        this.color = "#fdc027";
      } else if (this.eco) {
        this.originalColor = "#72d084";
        this.color = "#72d084";
      } else if (this.health) {
        this.originalColor = "#6191fe";
        this.color = "#6191fe";
      } else {
        this.originalColor = "#29adf2";
        this.color = "#29adf2";
      }

      return "bg-[" + this.color + "]";
    },
    getBackGroundColor() {
      //this.setParametersByPath();
      const store = useMainStore();

      if (this.learn || store.key === "062a0213") {
        return "bg-[" + "#fdc027" + "]";
      } else if (this.eco || store.key === "02670138") {
        return "bg-[" + "#72d084" + "]";
      } else if (this.health || store.key === "08870277") {
        return "bg-[" + "#6191fe" + "]";
      } else {
        return "bg-[" + "#29adf2" + "]";
      }
    },
    eraseSelected() {
      this.learn = false;
      this.eco = false;
      this.health = false;
    },
    eraseSelectedSetBlue() {
      this.learn = false;
      this.eco = false;
      this.health = false;
      this.originalColor = "#29adf2";
      this.color = "#29adf2";
    },
    selectedCheck() {
      //this.setParametersByPath();
      return this.learn || this.eco || this.health;
    },
    getFontColor() {
      if (this.learn) {
        return "text-[" + "#fdc027" + "]";
      } else if (this.eco) {
        return "text-[" + "#72d084" + "]";
      } else if (this.health) {
        return "text-[" + "#6191fe" + "]";
      } else {
        return "text-[" + "#29adf2" + "]";
      }
    },
  },
});

const type = "website";
const url = "http://nadaciabluelemons.sk/";
const title = "Podporujeme dobré nápady | Nadácia Blue Lemons";
const description =
  "Naša nadácia podporuje dobré nápady v oblastiach vzdelávanie, eko a enviro a duševné zdravie.";
const mainImage = "http://nadaciabluelemons.sk/img/slider_test_3.d41bf665.png";

export default function getMeta(meta) {
  return [
    {
      hid: "description",
      name: "description",
      // eslint-disable-next-line no-undef
      content: (meta && meta.description) || description,
    },
    {
      hid: "og:type",
      property: "og:type",
      // eslint-disable-next-line no-undef
      content: (meta && meta.type) || type,
    },
    {
      hid: "og:url",
      property: "og:url",
      // eslint-disable-next-line no-undef
      content: (meta && meta.url) || url,
    },
    {
      hid: "og:title",
      property: "og:title",
      // eslint-disable-next-line no-undef
      content: (meta && meta.title) || title,
    },
    {
      hid: "og:description",
      property: "og:description",
      // eslint-disable-next-line no-undef
      content: (meta && meta.description) || description,
    },
    {
      hid: "og:image",
      property: "og:image",
      // eslint-disable-next-line no-undef
      content: (meta && meta.mainImage) || mainImage,
    },
    {
      hid: "twitter:url",
      name: "twitter:url",
      // eslint-disable-next-line no-undef
      content: (meta && meta.url) || url,
    },
    {
      hid: "twitter:title",
      name: "twitter:title",
      // eslint-disable-next-line no-undef
      content: (meta && meta.title) || title,
    },
    {
      hid: "twitter:description",
      name: "twitter:description",
      // eslint-disable-next-line no-undef
      content: (meta && meta.description) || description,
    },
    {
      hid: "twitter:image",
      name: "twitter:image",
      // eslint-disable-next-line no-undef
      content: (meta && meta.mainImage) || mainImage,
    },
  ];
}


import { defineComponent } from "vue";
import ProjectDetail from "../components/common/ProjectDetail.vue";
import Data from "../api/projectDetails.json";
import { useHead } from "@vueuse/head";
import getSiteMeta from "../utils/getSiteMeta";
import { useRoute } from "vue-router";

export default defineComponent({
  name: "DetailView",
  components: {
    ProjectDetail,
  },
  data: () => {
    return {
      data: Data,
      project: {},
    };
  },
  mounted() {
    this.getProject();
    window.scrollTo(0, 0);
  },
  methods: {
    getProject() {
      Data.projects.forEach((element) => {
        if ("/podporili-sme/" + element.path == this.$route.path) {
          this.project = element;
        }
      });
    },
  },
  setup() {
    const route = useRoute();
    const meta = {
      title:
        route.params.name === "stoly-a-stolicky"
          ? "Stoly a stoličky | Nadácia Blue Lemons"
          : route.params.name === "ihriska"
          ? "Ihriská | Nadácia Blue Lemons"
          : route.params.name === "futbalove-ihrisko"
          ? "Futbalové ihrisko | Nadácia Blue Lemons"
          : "Enviro app | Nadácia Blue Lemons",
      description:
        route.params.name === "stoly-a-stolicky"
          ? "Projekt Stoly a stoličky je o zvýšení využiteľnosti verejného priestoru v galérii, múzeu a verejnom parku."
          : route.params.name === "ihriska"
          ? "Cieľom projektu bolo vytvoriť miesto pre deti a rodičov, ktoré oživuje verejný priestor obce a prehlbuje priateľstvá a medziľudské vzťahy."
          : route.params.name === "futbalove-ihrisko"
          ? "Cieľom projektu Futbalové ihrisko bolo zrekonštruovať oplotenie, ktoré sa nachádzalo v zlom technickom stave a ohrozovalo prevádzku."
          : "Cieľom projektu Enviroapp je hravou formou inšpirovať ľudí, aby používali alternatívne formy dopravy a prispeli tak k znižovaniu hladiny CO2 v ovzduší.",
      url:
        route.params.name === "stoly-a-stolicky"
          ? "http://nadaciabluelemons.sk/podporili-sme/stoly-stolicky"
          : route.params.name === "ihriska"
          ? "http://nadaciabluelemons.sk/podporili-sme/ihriska"
          : route.params.name === "futbalove-ihrisko"
          ? "http://nadaciabluelemons.sk/podporili-sme/futbalove-ihrisko"
          : "http://nadaciabluelemons.sk/podporili-sme/enviro-appo",
    };

    useHead({
      title: meta.title,
      meta: getSiteMeta(meta),
    });
  },
});

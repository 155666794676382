import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "card-container" }
const _hoisted_2 = { class: "w-[100%] max-w-[1033.5px] min-w-[289px] max-h-[931.5px]" }
const _hoisted_3 = { class: "overflow-hidden" }
const _hoisted_4 = ["src"]
const _hoisted_5 = { class: "text-left font-['Graphik'] font-[700] xs:text-[24px] lg:text-[32px] xs:leading-[36px] lg:leading-[52px] hover:cursor-pointer mt-[2%]" }
const _hoisted_6 = { class: "text-left font-['Montserrat'] font-[400] xs:text-[14px] lg:text-[16px] xs:leading-[21px] lg:leading-[160%] hover:cursor-pointer" }
const _hoisted_7 = { class: "text-left font-['Montserrat'] w-[72%] font-[400] xs:text-[14px] lg:text-[16px] xs:leading-[21px] lg:leading-[24px] hover:cursor-pointer mt-[1%]" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createVNode(_component_router_link, { to: $props.url }, {
          default: _withCtx(() => [
            _createElementVNode("img", {
              class: "w-[100%] h-auto block transition duration-500 hover:scale-[1.1] cursor-pointer",
              src: require('@/assets/' + $props.imagePath + '')
            }, null, 8, _hoisted_4)
          ]),
          _: 1
        }, 8, ["to"])
      ]),
      _createElementVNode("p", _hoisted_5, _toDisplayString($props.titleText), 1),
      _createElementVNode("p", _hoisted_6, _toDisplayString($props.subtitleText), 1),
      _createElementVNode("p", _hoisted_7, _toDisplayString($props.cardText), 1)
    ])
  ]))
}

// import SlideItem from "./SlideItem.vue";
import "vue3-carousel/dist/carousel.css";

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import { Carousel, Slide } from "vue3-carousel";
export default {
  components: {
    // SlideItem,
    Carousel,
    Slide,
  },
  data: function () {
    return {
      slides: [
        {
          id: 0,
          src: require("@/assets/slider_test_1.png"),
          heading: "Stoly a stoličky",
          text1: "Eko a enviro",
          text2: "Zdravie",
          url: "/podporili-sme/stoly-a-stolicky",
        },
        {
          id: 2,
          src: require("@/assets/slider_test_2.jpg"),
          heading: "Ihriská",
          text2: "Eko a enviro",
          text1: "Zdravie",
          url: "/podporili-sme/ihriska",
        },
        {
          id: 1,
          src: require("@/assets/slider_test_3.png"),
          heading: "Futbalové ihrisko",
          text1: "Eko a enviro",
          text2: "Zdravie",
          url: "/podporili-sme/futbalove-ihrisko",
        },
        {
          id: 0,
          src: require("@/assets/slider_test_1.png"),
          heading: "Stoly a stoličky",
          text1: "Eko a enviro",
          text2: "Zdravie",
          url: "/podporili-sme/stoly-a-stolicky",
        },
        {
          id: 2,
          src: require("@/assets/slider_test_2.jpg"),
          heading: "Ihriská",
          text2: "Eko a enviro",
          text1: "Zdravie",
          url: "/podporili-sme/ihriska",
        },
        {
          id: 1,
          src: require("@/assets/slider_test_3.png"),
          heading: "Futbalové ihrisko",
          text1: "Eko a enviro",
          text2: "Zdravie",
          url: "/podporili-sme/futbalove-ihrisko",
        },
      ],
      innerWidth: 0,
      singleWidth: 0,
      currentIndex: 1,
      sliderInterval: null,
    };
  },
  methods: {
    nextSlide() {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      (this.$refs.myCarousel as any).next();
    },
    prevSlide() {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      (this.$refs.myCarousel as any).prev();
    },
  },
};


import { defineComponent } from "vue";
import AccordionMenu from "@/components/common/accordion/AccordionMenu.vue";
import { useHead } from "@vueuse/head";
import getSiteMeta from "../utils/getSiteMeta";

export default defineComponent({
  name: "MediaView",
  components: {
    AccordionMenu,
  },

  mounted() {
    window.scrollTo(0, 0);
  },

  setup() {
    const meta = {
      title: "Média | Nadácia Blue Lemons",
      description:
        "Kontakt pre média, logá na stiahnutie, tlačové správy Nadácii Blue Lemons",
      url: "http://nadaciabluelemons.sk/media",
    };

    useHead({
      title: meta.title,
      meta: getSiteMeta(meta),
    });
  },

  data: () => {
    return {
      accordion: [
        {
          heading: "2022",
          content: "Túto sekciu doplníme čoskoro.",
        },
      ],
    };
  },
  methods: {},
});

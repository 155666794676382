
import { useHead } from "@vueuse/head";
import { defineComponent } from "vue";
import getSiteMeta from "../utils/getSiteMeta";
import Newsletter from "@/components/common/Newsletter.vue";

export default defineComponent({
  name: "ChallengeView",
  components: {
    Newsletter,
  },

  mounted() {
    window.scrollTo(0, 0);
  },

  data: () => {
    return {
      showEmployee: false,
      showPublic: false,
      close: true,
    };
  },
  methods: {
    onClicked() {
      this.close = true;
    },
  },
  setup() {
    const meta = {
      title: "Výzvy | Nadácia Blue Lemons",
      description:
        "Máte dobrý nápad, ktorý zapadá do konceptu našej nadácie a zhoduje sa s jednou z našich oblastí? Zapojte sa do našich výziev.",
      url: "http://nadaciabluelemons.sk/vyzvy",
    };

    useHead({
      title: meta.title,
      meta: getSiteMeta(meta),
    });
  },
});


import { useHead } from "@vueuse/head";
import { defineComponent } from "vue";
import getSiteMeta from "../utils/getSiteMeta";

export default defineComponent({
  name: "FAQView",
  components: {},

  mounted() {
    window.scrollTo(0, 0);
  },

  setup() {
    const meta = {
      title: "Kontakt | Nadácia Blue Lemons",
      description:
        "Kontaktné informácie k Nadácii Blue Lemons. +421 901 903 234 , info@nadaciabluelemons.sk",
      url: "http://nadaciabluelemons.sk/kontakt",
    };

    useHead({
      title: meta.title,
      meta: getSiteMeta(meta),
    });
  },

  data: () => {
    return {
      email: "",
      emailBlured: false,
      valid: false,
      submitted: false,
      name: "",
      message: "",
      checkBox: false,
      clicked: false,
    };
  },
  computed: {},
  methods: {
    isDisabled: function () {
      if (
        this.name.length != 0 &&
        this.checkBox &&
        this.message.length != 0 &&
        this.valid
      ) {
        return false;
      }
      return true;
    },
    validate: function () {
      this.emailBlured = true;
      if (this.validEmail(this.email)) {
        this.valid = true;
      }
    },
    validEmail: function (email: string) {
      var re = /(.+)@(.+){2,}\.(.+){2,}/;
      return re.test(email.toLowerCase());
    },
    submit: function () {
      this.validate();
      if (this.valid) {
        //THIS IS WHERE YOU SUBMIT DATA TO SERVER
        this.submitted = true;
      }
    }, //end submit
  },
});


import { defineComponent } from "vue";
import CardComponent from "../components/shared/cards/CardComponent.vue";
import Data from "../api/project.json";
import { useHead } from "@vueuse/head";
import getSiteMeta from "../utils/getSiteMeta";

export default defineComponent({
  name: "SupportedView",
  components: {
    CardComponent,
  },
  data: () => {
    return {
      data: {},
      route: String,
    };
  },
  mounted() {
    window.scrollTo(0, 0);
    this.getProject();
  },
  methods: {
    getProject() {
      this.data = Data.projects;
    },
  },
  setup() {
    const meta = {
      title: "Podporili sme | Nadácia Blue Lemons",
      description:
        "Podporujeme projekty v oblastiach vzdelávanie, eko a enviro a zdravie.",
      url: "http://nadaciabluelemons.sk/podporili-sme",
    };

    useHead({
      title: meta.title,
      meta: getSiteMeta(meta),
    });
  },
});
